import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as Translation } from 'react-intl';
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';
import css from './NoAds.scss';

import { fileNames } from 'Constants/imageUrls';
import { WEBP_TYPE } from 'Constants/images';

const NoAds = ({ selectedLocation, parentLocation }) => {
    const noAdsInLocationValues = { selectedLocation };
    const adsInParentLocationValues = { parentLocation };

    return (
        <div className={ `${css.noAds}` }>
            <ImageWrapper
                fileName={ fileNames.NO_RESULTS }
                types={ WEBP_TYPE }
                className={ css.image } />
            <div className={ css.title }><Translation id="noAdsInLocation" values={ noAdsInLocationValues } /></div>
            <div className={ css.subTitle }><Translation id="adsInParentLocation" values={ adsInParentLocationValues } /></div>
        </div>
    );
};

NoAds.propTypes = {
    selectedLocation: PropTypes.string.isRequired,
    parentLocation: PropTypes.string
};

export default NoAds;
