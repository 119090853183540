import { convertToAbsoluteImageURI } from 'Helpers/images';
import { SVG_EXT, X } from 'Constants/images';

/**
 * It is called dynamically from buildSchema()
 * @param props
 * @returns {{contactPoint: [{'@type': string, telephone: (string|*), contactType: (string|*), email}], '@type': string, potentialAction: {'query-input': string, '@type': string, target: (string|*)}, name, logo, description, inLanguage: (string|*), '@context': string, url: *, sameAs: (string[]|string[]|*)}}
 */
const buildWebSite = props => {
    const hostUrl = props.marketConfig.get('host');
    const type = props.marketConfig.get('SEO', 'sitelinksSearchStructuredData');
    const types = {
        slug: {
            searchUrl: `${hostUrl}/items/q-{search_term_string}`
        },
        query: {
            searchUrl: `${hostUrl}/search?q={search_term_string}`
        },
        both: {
            searchUrl: `${hostUrl}/items/q-{search_term_string}?q={search_term_string}`
        }
    };
    const searchUrl = types[type] ? types[type].searchUrl : types.slug.searchUrl;
    const staticAsseturl = props.marketConfig.get('staticAssets');
    const brandLogo = props.marketConfig.get('brandLogo');
    const schemaConfig = props.marketConfig.get('schema');

    if (!(searchUrl && staticAsseturl && brandLogo && schemaConfig)) {
        throw new Error('Market config is not available');
    }

    return {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        url: schemaConfig.url ?? hostUrl,
        name: schemaConfig.name,
        logo: convertToAbsoluteImageURI(brandLogo, SVG_EXT, staticAsseturl, X),
        description: props?.seoDescription || schemaConfig.description,
        inLanguage: schemaConfig.inLanguage,
        sameAs: schemaConfig.sameAs, // Replace this for dynamically retrieved footer links
        contactPoint: [
            {
                '@type': 'ContactPoint',
                telephone: schemaConfig.telephone,
                contactType: schemaConfig.contactType,
                email: schemaConfig.email
            }
        ],
        potentialAction: {
            '@type': 'SearchAction',
            target: searchUrl,
            'query-input': 'required name=search_term_string'
        }
    };
};

export default buildWebSite;
