'use strict';

/* eslint-disable no-magic-numbers */

const { listingUrlRegex } = require('Constants/listing');

function getSeoPage(rel, searchParams) {
    const pageChar = 5;

    const searchPath = searchParams.replace(/page=[0-9]+/, match => {
        const currentPage = parseInt(match.substr(pageChar), 10);
        const param = (rel === 'prev') ? `page=${currentPage - 1}` : `page=${currentPage + 1}`;

        return param;
    }
    );

    return searchPath;
}

function getLinks(currentPage, hostUrl, path, totalPages, searchParams, searchPath, hasNextPageUrl) {
    let linkPrevPage = [];
    let linkNextPage = [];
    const maxPage = 1000;
    const pathname = searchPath || path;

    if (currentPage && currentPage <= totalPages && currentPage > 1) {
        linkPrevPage = [
            { rel: 'prev', href: `${hostUrl}${(currentPage == 1) ? pathname : getSeoPage('prev', searchParams)}` }
        ];
    }

    if (currentPage && currentPage < totalPages && currentPage < maxPage && hasNextPageUrl) {
        linkNextPage = [
            { rel: 'next', href: `${hostUrl + getSeoPage('next', searchParams)}` }
        ];
    }
    else if (!currentPage && hasNextPageUrl) {
        linkNextPage = [
            {
                rel: 'next', href: `${hostUrl}${pathname}${searchPath ? '&page=1' : '?page=1'}`
            }
        ];
    }

    return { linkPrevPage, linkNextPage };
}

const getCrosslinkPath = ({ location, routes, landingPageRoutes }) => {
    const { pathname, search } = location;
    const match = listingUrlRegex.exec(pathname);

    if (pathname === '/') {
        return '/';
    }
    else if (match) {
        if (!routes.listing) {
            return null;
        }

        let path = routes.listing;

        if (match[5]) {
            path += match[5];
        }

        if (search) {
            const filterString = search.split('filter=')[1];
            const filters = filterString && filterString.split('%2C');

            if (filters && filters.length > 0) {
                let filterQuery = '';

                filters.forEach(item => {
                    if (item.includes('make')) {
                        filterQuery += item;
                    }
                    if (item.includes('model')) {
                        filterQuery = filterQuery ? `${filterQuery}%2C${item}` : item;
                    }
                });

                path = filterQuery ? `${path}?filter=${filterQuery}` : path;
            }
        }
        return path;
    }
    else if (pathname === landingPageRoutes['sell-organic']) {
        return routes['sell-organic'];
    }
    else if (pathname === landingPageRoutes['buy-organic']) {
        return routes['buy-organic'];
    }
    else if (pathname === landingPageRoutes['finance-organic']) {
        return routes['finance-organic'];
    }

    return null;
};

function getCrossLinkedTag(marketConfig, location) {
    const { lang, host, olxAutos = {}, landingPageRoutes = {}} = marketConfig;
    const seoContent = olxAutos.SEOContent;
    const crossLinkedUrls = seoContent && seoContent.crossLinkedUrls;
    const crossLinkedTags = [];

    if (crossLinkedUrls && crossLinkedUrls.length) {
        crossLinkedUrls.forEach(item => {
            const crossLinkPath = getCrosslinkPath({ location, routes: item.routes, landingPageRoutes });

            if (crossLinkPath) {
                crossLinkedTags.push([{ rel: 'alternate', hreflang: item.lang.toLowerCase(), href: `${ item.baseUrl }${ crossLinkPath }` }]);
            }
        });
        const href = `${host}${location.pathname}${location.search}`;

        crossLinkedTags.push([{ rel: 'alternate', hreflang: lang.toLowerCase(), href }]);
        crossLinkedTags.push([{ rel: 'alternate', hreflang: 'x-default', href }]);
    }

    return crossLinkedTags;
}

module.exports = { getLinks, getSeoPage, getCrossLinkedTag };
