export const SEARCH_PAGE_VIEW = 'spv';
export const LISTING_PAGE_VIEW = 'lpv';
export const DETAILS_PAGE_VIEW = 'dpv';
export const RESULTS_PAGE = 'results';
export const DETAILS_PAGE = 'details';
export const ALL_CATEGORIES = 'all-categories';
export const ANDBEYOND = 'andbeyond';
export const ANDBEYOND_MOBILE_ID = 'Mobile_Allpages_1x1';
export const ANDBEYOND_DESKTOP_ID = 'Desktop_Allpages_1x1';
export const DESKTOP_SKIN_HOME_LEFT = 'Desktop_Left_125x600';
export const DESKTOP_SKIN_HOME_RIGHT = 'Desktop_Right_125x600';
export const DESKTOP_SKIN_RESULT_LEFT = 'Desktop_Left_125x600';
export const DESKTOP_SKIN_RESULT_RIGHT = 'Desktop_Right_125x600';
