import React from 'react';
import css from './LoadingPlaceholder.scss';

const LoadingPlaceholder = () => {
    return (
        <div className={ css.loadingPlaceholder }>
            <div className={ css.card }>
                <div className={ `${ css.content } ${ css.animatedBackground }` } />
            </div>
        </div>
    );
};

export default LoadingPlaceholder;
