/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { css as uicss } from 'panamera-react-ui';
import classNames from 'classnames';
import NoResults from 'Components/NoResults/NoResults';
import BannerImage from 'Components/BannerImage/BannerImage';
import css from '../Home.desktop.scss';
import Listing from './HomeListing';
import { FormattedMessage as Translation } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withCookies from 'HOCs/withCookies/withCookies';
import { HOME } from 'Constants/tracking';
import withConfig from 'HOCs/withConfig/withConfig';
import AsyncAutosHomePageWidgets from '../../../olxautos/components/AutosHomePageWidgets/AsyncAutosHomePageWidgets';
import { EvaluationConstants } from 'olx-autos-landing-page';
import SITE_CODES from 'Constants/siteCodes';
import { HOME_BANNER_DESKTOP_SELL } from 'Constants/widgets';
import { configSelector } from 'Selectors/config';
import { BANNER_CAROUSEL_WIDGETS } from '../../../pages/Listing/ListingOld/ListingPageContent/Constant';
import { getShowBannerCarousel, getCarouselHomePageData } from 'Selectors/carousel';
import CookieManager from 'Helpers/cookies';
import { EXPIRATION_DATE, RESET_COOKIE_VALUE_LIMIT } from '../../../constants/carousel';
import withSessionFeature from 'HOCs/withSessionFeature/withSessionFeature';
import { showHomepageBanner } from 'Helpers/item';

const { grid } = uicss;

export const HomeContent = ({
    items,
    isFetchingItems,
    onViewItem,
    location,
    enabledRealImpressions,
    onLoadNextPage,
    renderBanners,
    feedsTitle,
    adIdsToUpdate,
    showLoadMore,
    offline,
    extraTrackAttr,
    config,
    cookies,
    handleIconClick,
    carouselHomePage,
    selectedLocation,
    sessionFeatures
}) => {
    const title = <Translation id="HaTSTitle" />;
    const cancelButton = <Translation id="HaTSCancelButton" />;
    const acceptButton = <Translation id="HaTSAcceptButton" />;
    const { SELLER_STATE_COOKIE, EVALUATION_STATES } = EvaluationConstants;
    const seller_state = cookies.get(SELLER_STATE_COOKIE);
    const siteCode = config.get('siteCode');

    const cookiehomePageBanner = CookieManager.readCookie('home_page_banner');

    useEffect(() => {
        if (!cookiehomePageBanner || (cookiehomePageBanner && RESET_COOKIE_VALUE_LIMIT === Number(cookiehomePageBanner))) {
            CookieManager.createCookie('home_page_banner', 1, EXPIRATION_DATE);
        }
        else {
            CookieManager.createCookie('home_page_banner', Number(cookiehomePageBanner) + 1, EXPIRATION_DATE);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showAutosHomePageWidgets = (!!config.get('showAutosHomePageWidget'));
    const autosInitialState = EVALUATION_STATES.INITIAL === seller_state || !seller_state;

    function getHomePageBanner(showAutosHomePageWidgets, offline) {
        if (!showAutosHomePageWidgets) {
            return;
        }
        if (showAutosHomePageWidgets) {
            if (autosInitialState && [SITE_CODES.OLX_IN, SITE_CODES.OLX_TR, SITE_CODES.OLX_CO].includes(siteCode)) {
                // eslint-disable-next-line consistent-return
                return (
                    <BannerImage
                        offline={ offline }
                        frmPage={ HOME }
                        widgetToFetch={ HOME_BANNER_DESKTOP_SELL } />
                );
            }
            // eslint-disable-next-line consistent-return
            return (
                <div className={ classNames(css.autosBannerWidget) }>
                    <AsyncAutosHomePageWidgets handleIconClick={ handleIconClick } />
                </div>
            );
        }
        // eslint-disable-next-line consistent-return
        return null; // <BannerImage offline={ offline } frmPage={ HOME } />;
    }

    const showBanner = showHomepageBanner(carouselHomePage, selectedLocation, 'pwa_desktop');

    return (
        <section className={ css.home } data-aut-id="home_content_desktop">
            <div className={ classNames({ [css.bannerWrapper]: showBanner }) }>
                {
                    showBanner
                        ? <BannerImage
                            homeContentProjectCarousel={ true }
                            widgetToFetch={ carouselHomePage?.widgetName?.pwa_desktop }
                            frmPage={ HOME }
                        />
                        : null
                }
            </div>

            <div className={ grid.row }>
                {renderBanners('top', adIdsToUpdate)}
                {/* {renderBanners('right', adIdsToUpdate)} */}
                <div
                    className={ classNames(
                        grid.col,
                        grid.marginContainer,
                        grid.xl12,
                        grid.l12,
                        grid.m12,
                        grid.s12,
                        css.bannersContainer
                    ) }
                >
                    {!isFetchingItems && !items.length && <NoResults />}
                    {(isFetchingItems || items.length !== 0) && (
                        <Listing
                            onLoadNextPage={ onLoadNextPage }
                            header={ feedsTitle }
                            showLoadMore={ showLoadMore }
                            items={ items }
                            isFetching={ isFetchingItems }
                            enabledRealImpressions={ enabledRealImpressions }
                            onViewItem={ onViewItem }
                            location={ location }
                            visualizationType="grid"
                            itemPostPlaceholderOrigin="home"
                            fourColumns
                            extraTrackAttr={ extraTrackAttr }
                        />
                    )}
                    {renderBanners('bottom', adIdsToUpdate)}
                </div>
            </div>
            { sessionFeatures.includes('test') && <div className={ css.testcomponent }>This is a test component showing feature</div> }
        </section>
    );
};

HomeContent.propTypes = {
    items: PropTypes.array,
    isFetchingItems: PropTypes.bool,
    onViewItem: PropTypes.func,
    location: PropTypes.object,
    enabledRealImpressions: PropTypes.bool,
    onLoadNextPage: PropTypes.func,
    renderBanners: PropTypes.func,
    adIdsToUpdate: PropTypes.array,
    feedsTitle: PropTypes.node,
    showLoadMore: PropTypes.bool,
    offline: PropTypes.bool,
    extraTrackAttr: PropTypes.object.isRequired,
    config: PropTypes.shape({
        get: PropTypes.func
    }),
    cookies: PropTypes.shape({
        get: PropTypes.func.isRequired,
        set: PropTypes.func.isRequired
    }),
    handleIconClick: PropTypes.func,
    carouselHomePage: PropTypes.object,
    selectedLocation: PropTypes.object,
    sessionFeatures: PropTypes.array.isRequired
};

HomeContent.defaultProps = {
    items: [],
    renderBanners: () => {},
    adIdsToUpdate: [],
    offline: false,
    selectedLocation: {}
};

export const mapStateToProps = state => {
    const showBannerCarouselforHomePage = getShowBannerCarousel(configSelector(state)?.features, BANNER_CAROUSEL_WIDGETS.SHOW_BANNER_FOR_CATEGORY);
    const carouselHomePage = getCarouselHomePageData(showBannerCarouselforHomePage);

    return {
        carouselHomePage,
        selectedLocation: state?.locations?.selectedLocation
    };
};

export default compose(
    withSessionFeature,
    withCookies,
    withConfig,
    connect(mapStateToProps)
)(HomeContent);
