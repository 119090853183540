import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ORIGINAL_ITEMS, SUGGESTED_ITEMS } from 'Constants/items';
import css from './LabelText.desktop.scss';

class LabelText extends Component {
    static propTypes = {
        label: PropTypes.string,
        term: PropTypes.string,
        total: PropTypes.number,
        labelType: PropTypes.oneOf([ORIGINAL_ITEMS, SUGGESTED_ITEMS]),
        showOriginalItems: PropTypes.bool
    };

    getTermInBold() {
        const { label, term } = this.props;

        const startIndex = label.indexOf('${');
        const endIndex = label.indexOf('}');

        return [label.slice(0, startIndex), <b key={ `label-term-${term}` }>&quot;{term}&quot;</b>, label.slice(endIndex + 1)];
    }

    checkLabelCondition() {
        const { labelType, label, showOriginalItems } = this.props;

        switch (labelType) {
            case SUGGESTED_ITEMS:
                return !!label && showOriginalItems;

            case ORIGINAL_ITEMS:
            default:
                return false;
        }
    }
    render() {
        const { total } = this.props;

        if (this.checkLabelCondition()) {
            return (
                <p className={ css.label }>
                    <span className={ css.labelText }>{this.getTermInBold()}</span>
                    <span className={ css.adCount }>{total}&nbsp;ads</span>
                </p>
            );
        }
        return null;
    }
}

export default LabelText;
