import React from 'react';
import PropTypes from 'prop-types';
import DefaultHeader from '../DefaultHeader.desktop';
import withRouter from 'HOCs/withRouter';
import { compose } from 'redux';
import { decodeSearch } from 'Helpers/strings';
import { connect } from 'react-redux';
import withConfig from 'HOCs/withConfig/withConfig';
import toggleFilters from 'Actions/toggleFilters';
import {
    getLoggedUser,
    userIsLogged
} from 'Selectors/user';

export const SearchHeader = props => {
    const {
        params,
        _toggleFilters,
        loginCallback,
        hideActionButtons,
        showHelpButton,
        hideSearchBar,
        monetTrackingParams,
        hideMultiLang,
        offline,
        isPaidCampaign,
        isMeetingPage,
        isOlxAutosLandingPage,
        seller_state,
        categoryId,
        config,
        flowStep
    } = props;
    const searchTerm = decodeSearch(params.text);
    const user = props.userIsLogged ? props.loggedUser : undefined;
    const isCarCompareEnabled = config.get('olxAutos', 'isCarCompareEnabled');

    return (<DefaultHeader
        seller_state={ seller_state }
        toggleFilters={ _toggleFilters }
        searchTerm={ searchTerm }
        isOnListing
        user={ user }
        params={ params }
        loginCallback={ loginCallback }
        hideActionButtons={ hideActionButtons }
        showHelpButton={ showHelpButton }
        hideSearchBar={ hideSearchBar }
        monetTrackingParams={ monetTrackingParams }
        isMeetingPage={ isMeetingPage }
        hideMultiLang={ hideMultiLang }
        offline={ offline }
        isPaidCampaign={ isPaidCampaign }
        categoryID={ categoryId }
        isOlxAutosLandingPage={ isOlxAutosLandingPage }
        showCompareIcon={ isCarCompareEnabled }
        flowStep={ flowStep }
    />);
};

SearchHeader.propTypes = {
    _toggleFilters: PropTypes.func,
    params: PropTypes.object,
    loggedUser: PropTypes.object.isRequired,
    userIsLogged: PropTypes.bool.isRequired,
    hideActionButtons: PropTypes.bool,
    showHelpButton: PropTypes.bool,
    hideSearchBar: PropTypes.bool,
    isMeetingPage: PropTypes.bool,
    monetTrackingParams: PropTypes.object,
    loginCallback: PropTypes.func,
    hideMultiLang: PropTypes.bool,
    offline: PropTypes.bool,
    isPaidCampaign: PropTypes.bool,
    categoryId: PropTypes.string,
    isOlxAutosLandingPage: PropTypes.bool,
    seller_state: PropTypes.string,
    config: PropTypes.shape({
        get: PropTypes.func.isRequired
    }).isRequired,
    flowStep: PropTypes.string
};

SearchHeader.defaultProps = {
    _toggleFilters: () => {},
    hideActionButtons: false,
    showHelpButton: false,
    hideSearchBar: false,
    hideMultiLang: false,
    offline: false,
    categoryId: ''
};

export const mapStateToProps = state => ({
    loggedUser: getLoggedUser(state),
    userIsLogged: !!userIsLogged(state)
});

export const mapDispatchToProps = dispatch => ({
    _toggleFilters: state => dispatch(toggleFilters(state))
});

export default compose(
    withRouter,
    withConfig,
    connect(mapStateToProps, mapDispatchToProps)
)(SearchHeader);
