import React from 'react';
import css from './withSkinAds.scss';
import AdvertisingComponent from 'Components/AdvertisingComponent/AdvertisingComponent';
import { isMobile } from 'Helpers/devices';
import { DESKTOP_SKIN_HOME_LEFT, DESKTOP_SKIN_HOME_RIGHT, DESKTOP_SKIN_RESULT_LEFT, DESKTOP_SKIN_RESULT_RIGHT } from 'Constants/baxter';
import { isScreenAboveDesktopXl } from 'Helpers/devices';

const renderBanners = (slot, pageName) => {
    if (!isScreenAboveDesktopXl()) {
        return null;
    }

    let dynamicAdId = '';
    const slotToDynamicAdIdMap = {
        home: {
            left: DESKTOP_SKIN_HOME_LEFT,
            right: DESKTOP_SKIN_HOME_RIGHT
        },
        results: {
            left: DESKTOP_SKIN_RESULT_LEFT,
            right: DESKTOP_SKIN_RESULT_RIGHT
        }
    };

    if (slotToDynamicAdIdMap[pageName]) {
        dynamicAdId = slotToDynamicAdIdMap[pageName][slot];
    }
    return (
        <AdvertisingComponent
            slot={ slot }
            page={ pageName }
            dynamicAdId={ dynamicAdId }
        />
    );
};

const withSkinAds = pageName => Component => {
    const WrappedComponent = props => {
        const shouldRenderComponent = isMobile;

        return shouldRenderComponent ? (
            <Component { ...props } />
        ) : (
            <>
                <div className={ css.adWrapper }>
                    <div className={ css.leftAdWrapper }>
                        <div>
                            {renderBanners('left', pageName)}
                        </div>
                    </div>
                    <div className={ css.middleContentWrapper }>
                        <Component { ...props } />
                    </div>
                    <div className={ css.rightAdWrapper }>
                        <div>
                            {renderBanners('right', pageName)}
                        </div>
                    </div>
                </div>
            </>

        );
    };

    WrappedComponent.displayName = `withSkinAds(${Component.displayName || Component.name || 'Component'})`;

    return WrappedComponent;
};

export default withSkinAds;
