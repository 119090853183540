/* eslint-disable no-magic-numbers */
export const elapsedTime = previousTimestamp => {
    const radix = 10;
    const currentTimestamp = Date.now();
    const elapsedTime = currentTimestamp - parseInt(previousTimestamp, radix);
    const seconds = Math.floor(elapsedTime / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    return days;
};
