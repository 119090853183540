import { PLATFORM } from 'Constants/device.APP_TARGET';

export const widgetConfigUrl = widgetName => `/api/cxe/widgets/${widgetName}`;

const HEADERS = {
    'X-PANAMERA-CLIENT-ID': `web_${PLATFORM}`
};

export const fetchWidgetData = widgetUrlName => (dispatch, getState, { get }) => {
    return dispatch(get(widgetConfigUrl(widgetUrlName), 'WIDGET_CONFIG', {}, HEADERS));
};
