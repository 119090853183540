const trackingEvents = {
    projectTapExpand: 'project_detail_page_tap_expand',
    projectEnquireButtonTap: 'project_detail_page_enquire_now',
    projectViewImage: 'project_detail_page_view_image',
    projectSwitchUnitTab: 'project_detail_page_switch_unit_config_tab',
    projectTapOnMap: 'project_detail_page_tap_on_map',
    reListingProjectTap: 're_listings_tap_project',
    reListingImpressions: 're_listings_project_impressions',
    reListingOpen: 're_listings_page_open',
    reListingPageEnd: 're_listings_end_of_page',
    reListingChangeLocation: 're_listings_change_location',
    enquiryFormTapSubmit: 'enquiry_form_tap_submit',
    enquiryFormAccept: 'edit_enquiry_form_accept',
    enquiryFormDecline: 'edit_enquiry_form_decline',
    enquiryFormSuccess: 'enquiry_page_success',
    enquiryFormSubmitSuccess: 'enquiry_form_submit_success',
    reTapProjectsEntry: 're_tap_projects_entry',
    reLoadProjectsEntry: 're_load_projects_entry',
    iAmInterested: 'show_interest',
    requestCallBack: 'call_me',
    testDrive: 'test_drive',
    notifyMe: 'notify_me',
    storeVisit: 'store_visit',
    tradeIn: 'trade_in',
    hometopbannershown: 'home_top_banner_shown',
    projectdetailpagecallnow: 'project_detail_page_call_now',
    homepagebannerclicked: 'homepage_banner_clicked'

};

export default trackingEvents;
