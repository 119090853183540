import React from 'react';
import css from './NoResults.scss';
import PropTypes from 'prop-types';
import { FormattedMessage as Translation } from 'react-intl';
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';
import { WEBP_TYPE } from 'Constants/images';
import withConfig from 'HOCs/withConfig/withConfig';

const NoResults = ({ config }) => {
    const errorFigure = config.get('listing', 'errorFigure');

    return (
        <div className={ css.container }>
            <h3 className={ css.title }><Translation id="noResultsPageTitle" /></h3>
            <p className={ css.text }><Translation id="noResultsPageText" /></p>
            <ImageWrapper
                fileName={ errorFigure }
                types={ WEBP_TYPE }
                className={ css.image } />
        </div>
    );
};

NoResults.propTypes = {
    config: PropTypes.shape({
        get: PropTypes.func.isRequired
    }).isRequired
};

export { NoResults };

export default withConfig(NoResults);
