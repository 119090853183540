import lazyLoad from 'Components/asyncLoading/lazyLoad';
import LoadingPlaceholder from './LoadingPlaceholder';

const AsyncAutosHomePageWidgets = lazyLoad({
    loader: () => import(
        /* webpackChunkName: "home-page-widgets" */
        /* webpackPreload: true */
        './AutosHomePageWidgets'),
    loading: LoadingPlaceholder
});

export default AsyncAutosHomePageWidgets;
