/* global window */
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import css from './BannerImage.scss';
import withConfig from 'HOCs/withConfig/withConfig';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import { convertToAbsoluteImageURI, getBackgroundBanner } from 'Helpers/images';
import { CHOSEN_OPTION_CALL_US, CLICK_TO_CALL_BANNER_EVENT, HOME, LISTING, SELECT_FROM_CAR_CATEGORY, USER_TYPE } from 'Constants/tracking';
import { fetchWidgetData, widgetConfigUrl } from 'Actions/widgetConfig';
import Button from 'Components/Button/Button';
import { HOME_BANNER_DESKTOP } from 'Constants/widgets';
import { PNG_EXT, X } from 'Constants/images';
import withRouter from 'HOCs/withRouter';
import { userIsLogged, getLoggedUser } from 'Selectors/user';
import setLoginModalState from 'Actions/setLoginModalState';
import withTrack from 'HOCs/withTrack/withTrack';
import CookieManager from 'Helpers/cookies';
import withSessionLong from 'HOCs/withSessionLong/withSessionLong';
import { paramsToQueryString, queryStringToParams } from 'Helpers/url';
import { CONFIG } from 'Components/Listing/components/constants';
import { slugify } from 'Helpers/strings';
import trackingEvents from 'Constants/trackingEvents';

import { EvaluationConstants, LayoutSelector, CampaingHelpers, locationHelpers, LayoutHelper } from 'olx-autos-landing-page';

export const BannerImage = ({
    config,
    preNotificationAvailable,
    offline,
    frmPage,
    track,
    bannerHref,
    fetchBannerConfig,
    widgetData,
    router,
    _userIsLogged,
    setLoginModalState,
    trackingOrigin,
    locationId,
    widgetToFetch,
    userId,
    userSessionLong,
    listingPageCarousel,
    callCustomerCareBanner,
    selectedLocation,
    homeContentProjectCarousel
}) => {
    const staticAssetUrl = config.get('staticAssets');
    const fetchBannerFromCXE = config.get('fetchBannerFromCXE');
    const bannerImageClickable = config.get(CONFIG.BANNER)?.[CONFIG.CLICKABLE_IMAGE] ?? false;
    const defaultHref = config.get('banner', 'defaultHref');
    const {
        data: {
            name: widgetName = '',
            data: {
                cta: {
                    buttons: ctaButtons = [],
                    images: ctaImages = [],
                    style: ctaButtonStyle = {},
                    wrapperStyle = {}
                } = {},
                image: {
                    url: homeBannerImageURL = '',
                    action: bannerImageAction = ''
                } = {},
                title: {
                    text: ctaTitle = ''
                } = {},
                action: redirectionUrl = '',
                tracking: tracking = {},
                is_real_estate_banner = false
            } = {},
            metadata: {
                style: widgetStyle = {},
                image_style: imageStyle = {},
                locationIds = []
            } = {}
        } = {},
        isError = false
    } = widgetData || {};

    const bannerImageUrl = fetchBannerFromCXE ? convertToAbsoluteImageURI(homeBannerImageURL, PNG_EXT, '', X, '', config.get('theme', 'id')) : getBackgroundBanner(config, frmPage);
    const widgetImageStyle = Object.keys(imageStyle).length === 0 ? widgetStyle : imageStyle;

    const getTrackingFields = (buttonName = '') => {
        return {
            origin: trackingOrigin,
            user_type: CookieManager.readCookie(EvaluationConstants.REPEAT_USER_COOKIE_NAME) ? USER_TYPE.REPEAT : USER_TYPE.FIRST_TIME,
            variants_shown: widgetName,
            browsing_location_id: locationId || 'unknown',
            reason: CookieManager.readCookie(EvaluationConstants.SELLER_STATE_COOKIE) || EvaluationConstants.EVALUATION_STATES.INITIAL,
            ...(buttonName && { chosen_option: buttonName }),
            ...LayoutHelper.getAcquisitionChannelTrackingValues()
        };
    };

    useEffect(() => {
        if (fetchBannerFromCXE) {
            fetchBannerConfig(widgetToFetch || HOME_BANNER_DESKTOP);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [widgetToFetch]);

    useEffect(() => {
        if (widgetName) {
            track('olxautos_widgets_shown', getTrackingFields());
            track(trackingEvents.hometopbannershown, {
                reason: 're_banner'
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [widgetName]);

    const handleClick = useCallback(() => {
        if (redirectionUrl && !is_real_estate_banner) {
            track(tracking?.event_name, tracking);
            return window.open(redirectionUrl, '_blank');
        }

        if (homeContentProjectCarousel && redirectionUrl) {
            track(tracking?.event_name, tracking);
            track(trackingEvents.homepagebannerclicked, {
                reason: 're_banner'
            });
            const projectsUrl = `projects/${slugify(selectedLocation?.name)}_g${selectedLocation?.id}`;

            router?.push(projectsUrl || '');
            return null;
        }

        if (bannerImageClickable && defaultHref) {
            return window.open(defaultHref, '_blank');
        }

        if (callCustomerCareBanner && bannerImageAction) {
            document.location.href = bannerImageAction;

            track(CLICK_TO_CALL_BANNER_EVENT, {
                chosen_option: CHOSEN_OPTION_CALL_US,
                select_from: SELECT_FROM_CAR_CATEGORY
            });

            return null;
        }

        if (bannerImageClickable && !callCustomerCareBanner) {
            return window.open(bannerImageAction, '_blank');
        }
        return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bannerHref, listingPageCarousel, homeContentProjectCarousel, redirectionUrl, tracking, is_real_estate_banner]);

    const handleCtaClicks = (actionLink, buttonName) => e => {
        track('olxautos_widget_button_clicked', getTrackingFields(buttonName));
        const [baseUrl, queryString] = actionLink.split('?');

        const redirectionUserParams = {
            redirection_session_id: userSessionLong || '',
            redirection_userid: userId || ''
        };

        const utmParams = { ...queryStringToParams(queryString), ...redirectionUserParams };
        const url = `${baseUrl}?${paramsToQueryString(utmParams)}`;

        if (url?.indexOf('//') === -1) {
            e.preventDefault();
        }
        if (url === '/post') {
            if (_userIsLogged) {
                router.push({
                    pathname: '/post'
                });
            }
            else {
                setLoginModalState({ state: true, redirectTo: '/post' });
            }
        }
        else if (url.includes('http')) {
            window.open(url, '_blank');
        }
        else if (widgetToFetch) {
            window.open(url, '_blank');
        }
        else {
            window.open(url, '_self');
        }
    };

    const handleImageClick = (actionName, actionUrl = null) => e => {
        e.preventDefault();

        if (actionUrl) {
            if (callCustomerCareBanner) {
                track(CLICK_TO_CALL_BANNER_EVENT, {
                    chosen_option: actionName,
                    select_from: SELECT_FROM_CAR_CATEGORY
                });

                if (actionName === CHOSEN_OPTION_CALL_US) {
                    document.location.href = actionUrl;
                    return null;
                }
            }
            return window.open(actionUrl, '_blank');
        }
        return null;
    };

    if (isError) {
        return null;
    }

    if (fetchBannerFromCXE || bannerImageUrl) {
        if (locationIds?.length && !locationIds?.includes(Number(locationId))) {
            return null;
        }

        return (
            <div
                className={ classnames({
                    [css.bannerImage]: !!bannerImageUrl,
                    [css.bannerHref]: !!bannerHref,
                    [css.listingBannerImage]: frmPage === LISTING,
                    [css.callCustomerCareBannerImage]: callCustomerCareBanner
                }) }
                style={ widgetStyle }
                data-aut-id="bannerImageWrapper"
                onClick={ handleClick }
            >
                <div
                    className={ classNames(css.image,
                        {
                            [css.imageClickable]: bannerImageClickable,
                            [css.withPreNotification]: preNotificationAvailable,
                            [css.withoutPreNotification]: !preNotificationAvailable,
                            [css.offline]: offline,
                            [css.listing_bg]: frmPage === LISTING,
                            [css.callCustomerCareBanner]: callCustomerCareBanner
                        },
                        css.imageSize
                    ) }
                    style={ { backgroundImage: bannerImageUrl ? `url(${staticAssetUrl}${bannerImageUrl})` : '', ...widgetImageStyle } }
                    data-aut-id="bannerImage"
                >

                    { fetchBannerFromCXE && <div
                        className={ classNames({
                            [css.callCustomerCareWrapper]: callCustomerCareBanner,
                            [css.ctaButtonWrapper]: !callCustomerCareBanner
                        }) }
                        style={ wrapperStyle }>

                        { ctaTitle && <p className={ classNames(css.ctaText) } >
                            {ctaTitle}
                        </p> }

                        { ctaImages?.length > 0 && <div className={ classNames(css.imageWrapper) }>
                            { ctaImages.map((image, index) =>
                                (<img
                                    key={ `cta_image_${index}` }
                                    src={ `${staticAssetUrl}${image.url}` }
                                    style={ image.style }
                                    onClick={ handleImageClick(image.name, image?.action) }
                                    className={ css.imageCss }
                                />)
                            )}
                        </div> }

                        { ctaButtons && ctaButtons.map((button, index) =>
                            (<Button
                                key={ `cta_button_${index}` }
                                className={ classNames(css.ctaButton, {
                                    [css.mr20]: index === 0
                                }) }
                                style={ ctaButtonStyle }
                                type="primaryBtn"
                                invert
                                onClick={ handleCtaClicks(button.action, button.text) }
                            >
                                { button.text }
                            </Button>)
                        )}
                    </div> }

                </div>
            </div>
        );
    }
    return null;
};

BannerImage.propTypes = {
    config: PropTypes.shape({
        get: PropTypes.func.isRequired
    }).isRequired,
    preNotificationAvailable: PropTypes.bool,
    offline: PropTypes.bool,
    frmPage: PropTypes.oneOf([HOME, LISTING]).isRequired,
    bannerHref: PropTypes.string,
    fetchBannerConfig: PropTypes.func,
    widgetData: PropTypes.object,
    track: PropTypes.func,
    trackingOrigin: PropTypes.string,
    router: PropTypes.object.isRequired,
    _userIsLogged: PropTypes.bool.isRequired,
    setLoginModalState: PropTypes.func.isRequired,
    locationId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    location: PropTypes.shape({
        query: PropTypes.object,
        pathname: PropTypes.string
    }),
    widgetToFetch: PropTypes.string,
    userId: PropTypes.string,
    userSessionLong: PropTypes.string,
    listingPageCarousel: PropTypes.bool,
    callCustomerCareBanner: PropTypes.bool,
    selectedLocation: PropTypes.object,
    homeContentProjectCarousel: PropTypes.bool
};

export const mapStateToProps = (state, ownProps) => {
    const queryString = ownProps && ownProps.location && ownProps.location.query;
    const isPaidCampaign = CampaingHelpers.isPaidCampaign(queryString, ownProps?.marketConfig?.get());
    const locationId = isPaidCampaign ? 'unknown' : locationHelpers.getLocationId(state.locations.selectedLocation);
    const userId = getLoggedUser(state).id;

    return {
        preNotificationAvailable: state.preNotification.preNotificationAvailable,
        widgetData: state.widgetConfig[widgetConfigUrl(ownProps.widgetToFetch || HOME_BANNER_DESKTOP)],
        _userIsLogged: userIsLogged(state),
        trackingOrigin: LayoutSelector.getTrackingOrigin(state, ownProps.location, ownProps.marketConfig),
        locationId,
        userId,
        selectedLocation: state.locations.selectedLocation
    };
};

export const mapDispatchToProps = dispatch => ({
    fetchBannerConfig: widgetUrlName => dispatch(fetchWidgetData(widgetUrlName)),
    setLoginModalState: state => dispatch(setLoginModalState(state))
});

export default compose(
    withRouter,
    withTrack,
    withConfig,
    withSessionLong,
    connect(mapStateToProps, mapDispatchToProps),
    React.memo
)(BannerImage);
